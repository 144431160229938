import LocalizedStrings from "react-localization"

const languages = new LocalizedStrings({
    ru: {
        title: 'Игорь Приймак - Front-End Разработчик',
        promo__title: 'Привет,',
        promo__subtitle: 'Меня зовут Игорь Приймак',
        promo__info: 'Front-end разработчик',
        promo__btn: 'подробнее',
        about__title: 'Обо мне',
        about__subtitle: 'Позвольте представиться',
        about__descr: 'Меня зовут Игорь. Я Front-End разработчик из Одессы. Я занимаюсь созданием сайтов, в том числе работаю с Wordpress. В процессе разработки я использую современные инструменты, которые позволяют обеспечить высокое качество веб-проектов.',
        about__main: 'Профиль',
        name: 'Имя:',
        myname: 'Игорь Приймак',
        age: 'Возраст:',
        rank: 'Работа:',
        myrank: 'Front-End Разработчик',
        gmail: 'почта:',
        telegram: 'Телеграм:',
        skills: 'навыки',
        resume__title: 'Резюме',
        resume__subtitle: 'Больше о моих навыках',
        resume__education: 'Образование',
        school: 'Черноморская ООШ',
        school_data: 'Сентябрь 2009 - Май 2020',
        school_info: 'Общеобразовательная Школа',
        school_descr: 'Общеобразовательная школа в Одесской области.',
        udemy: 'Udemy - Web-разработчик',
        udemy_data: 'Февраль 2022 - Апрель 2022',
        udemy_info: 'Udemy Курсы',
        udemy_descr: 'Курсы Udemy по Web-разработке, на которых я научился работать с планировщиком задач Gulp, Git, использовать методологию БЭМ.',
        univer: 'ВУЗ ОНТУ',
        univer_data: 'Сентябрь 2020 - настоящее время',
        univer_info: 'ВУЗ',
        univer_descr: 'Одесский Национальный Технологический Университет. Компьютерные науки. Кибербезопасность.',
        portfolio__title: 'Портфолио',
        portfolio__subtitle: 'Несколько моих работ',
        services__title: 'Услуги',
        services__subtitle: 'Что я могу для Вас сделать?',
        web: 'Веб-разработка',
        web_descr: 'Разработаю, сверстаю веб-сайт используя современные технологии. Адаптирую сайт под мобильные устройства. Оптимизирую скорость работы сайтов, проведу валидацию верстки по стандартам w3c.',
        consult: 'Консультация',
        consult_descr: 'Отвечу на технические вопросы, помогу решить вопросы связанные с созданием, оптимизацией и поддержкой веб-сайтов.'
    },
    en: {
        title: "Igor Pryimak - Front-End Developer",
        promo__title: 'Hello,',
        promo__subtitle: "I'm Igor Pryimak",
        promo__info: 'Front-end Developer',
        promo__btn: 'MORE',
        about__title: 'About',
        about__subtitle: 'Let me introduce myself',
        about__descr: "I'm Igor. I am a Front-End developer from Odessa. I create websites, including working with Wordpress. During the development process, I use modern tools that allow me to ensure high quality web projects.",
        about__main: 'Profile',
        name: 'Name:',
        myname: 'Igor Pryimak',
        age: 'Age:',
        rank: 'Job:',
        myrank: 'Front-End Developer',
        gmail: 'email:',
        telegram: 'Telegram:',
        skills: 'skills',
        resume__title: 'Resume',
        resume__subtitle: 'More of my credentials',
        resume__education: 'Education',
        school: 'Chernomorskaya School',
        school_data: 'September 2009 - May 2020',
        school_info: 'Comprehensive School',
        school_descr: 'Comprehensive school in Odessa region.',
        udemy: 'Udemy - Web-Developer',
        udemy_data: 'February 2022 - April 2022',
        udemy_info: 'Udemy Courses',
        udemy_descr: 'Udemy Web-Development Courses, where I have learned how to work with the Gulp task, Git, use the BEM methodology.',
        univer: 'University of ONTU',
        univer_data: 'September 2020 - present days',
        univer_info: 'University',
        univer_descr: 'Odessa National Technological University. Computer science. Cybersecurity.',
        portfolio__title: 'Portfolio',
        portfolio__subtitle: 'Check Out Some of My Works',
        services__title: 'Services',
        services__subtitle: 'What Can I Do For You?',
        web: 'Web-Development',
        web_descr: 'I can develop, make up a website using modern technologies. I will adapt the site for mobile devices, optimize website speed, validate the layout according to w3c standards.',
        consult: 'Consultancy',
        consult_descr: 'I can help with technical questions, help solve issues related to the creation, optimization and support of websites.'

    },
    ua: {
        title: "Ігор Приймак - Front-End Розробник",
        promo__title: 'Привiт,',
        promo__subtitle: "Мене звуть Ігор Приймак    ",
        promo__info: 'Front-end розробник',
        promo__btn: 'Більше',
        about__title: 'Про мене',
        about__subtitle: 'Дозвольте представитися',
        about__descr: 'Мене звати Ігор. Я Front-End розробник із Одеси. Я займаюся створенням сайтів, зокрема працюю з Wordpress. У процесі розробки я використовую сучасні інструменти, які дають змогу забезпечити високу якість веб-проектів.',
        about__main: 'Профіль',
        name: "Ім'я:",
        myname: 'Ігор Приймак',
        age: 'Вік:',
        rank: 'Робота:',
        myrank: 'Front-End розробник',
        gmail: 'пошта:',
        telegram: 'Телеграм:',
        skills: 'навички',
        resume__title: 'Резюме',
        resume__subtitle: 'Більше про мої навички',
        resume__education: 'Освіта',
        school: 'Чорноморська ЗОШ',
        school_data: 'Вересень 2009 - Травень 2020',
        school_info: 'Загальноосвітня школа',
        school_descr: 'Загальноосвітня школа Одеської області.',
        udemy: 'Udemy - Web-розробник',
        udemy_data: 'Лютий 2022 - Квітень 2022',
        udemy_info: 'Udemy Курси',
        udemy_descr: 'Курси Udemy з Web-розробки, на яких я навчився працювати з планувальником задач Gulp, Git та використовувати методологію БЕМ.',
        univer: 'ВНЗ ОНТУ',
        univer_data: 'Вересень 2020 – теперішній час',
        univer_info: 'ВНЗ',
        univer_descr: "Одеський Національний Технологічний Університет. Комп'ютерні науки. Кібербезпека.",
        portfolio__title: 'Портфоліо',
        portfolio__subtitle: 'Декілька моїх робіт',
        services__title: 'Послуги',
        services__subtitle: 'Що я можу зробити для вас?',
        web: 'Веб-розробка',
        web_descr: 'Розроблю, зверстаю веб-сайт, використовуючи сучасні технології. Адаптую сайт під мобільні пристрої. Оптимізую швидкість роботи сайтів, проведу валідацію верстки за стандартами w3c.',
        consult: 'Консультація',
        consult_descr: "Відповім на технічні питання, допоможу вирішити питання, пов'язані зі створенням, оптимізацією та підтримкою веб-сайтів.",
    }
})

export default languages